"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawKLine = void 0;
var tools_1 = require("../tools");
var watermark_1 = require("../watermark");
/**
 * K线绘图
 */
function drawKLine(svg, data, commonkdata, options, container) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var high_price = commonkdata.high_price, low_price = commonkdata.low_price, max_price = commonkdata.max_price, middle_price = commonkdata.middle_price, min_price = commonkdata.min_price, y_interval = commonkdata.y_interval, x_interval = commonkdata.x_interval, info_height = commonkdata.info_height;
    var pre_price = data.pre_price, decimal = data.decimal;
    var width = options.width, height = options.height, bar_width_ratios = options.bar_width_ratios, fontsize = options.fontsize;
    //股票名称
    var stockname = svg.Text(5, info_height - fontsize / 2);
    stockname.ele.textContent = data.name;
    stockname.attr({ class: "topinfo" });
    // 右上角MA10
    var ma10 = svg.Text(options.width - 5, info_height - options.fontsize / 2);
    ma10.ele.textContent = "MA10";
    ma10.attr({ class: "info_ma10", "text-anchor": "end" });
    // 右上角MA5
    var ma5 = svg.Text(options.width - ma10.ele.getComputedTextLength() - 8, info_height - options.fontsize / 2);
    ma5.ele.textContent = "MA5";
    ma5.attr({ class: "info_ma5", "text-anchor": "end" });
    // 右上角价格信息
    var ma_width = ma5.ele.getComputedTextLength() + ma10.ele.getComputedTextLength();
    var info_price = svg.Text(options.width - ma_width - 15, info_height - options.fontsize / 2);
    info_price.attr({ class: "info_price", "text-anchor": "end" });
    var changePriceInfoIndex = ChangePriceInfoIndex(info_price.ele, data, options, stockname.ele, ma_width);
    changePriceInfoIndex(null);
    // 上线
    var line1 = svg.HLine(0, info_height, options.width);
    line1.attr({ class: "infobox_border_k" });
    // 中线
    var line2 = svg.HLine(0, options.height / 2, options.width);
    line2.attr({ class: "infobox_border_k" });
    // 下线
    var line3 = svg.HLine(0, options.height - info_height, options.width);
    line3.attr({ class: "infobox_border_k" });
    // X轴分割线1
    var line4 = svg.VLine(options.width / 4, info_height, options.height - info_height * 2);
    line4.attr({ class: "infobox_border_k" });
    // X轴分割线2
    var line5 = svg.VLine(options.width / 2, info_height, options.height - info_height * 2);
    line5.attr({ class: "infobox_border_k" });
    // X轴分割线3
    var line6 = svg.VLine((options.width / 4) * 3, info_height, options.height - info_height * 2);
    line6.attr({ class: "infobox_border_k" });
    var first_show_kline = data.klines.slice(-(options.count + 1), -options.count);
    var origin_klines = data.klines;
    data.klines = data.klines.slice(-options.count);
    data.maArr = data.maArr.slice(-options.count);
    var rect_width = x_interval - 2 > 0 ? Math.round(x_interval) * bar_width_ratios : 1;
    var price_gap = high_price - low_price;
    var ih = options.height - info_height * 2;
    data.klines.map(function (item, index) {
        var _a;
        var ix = Number(((x_interval - rect_width) / 2 + index * x_interval).toFixed(0));
        ix = ix == 0 ? 1 : ix;
        var line_x = Number(ix + rect_width / 2);
        if (item.close - item.open >= 0) {
            var k_line_up = svg.VLine(line_x, Math.abs(Number((high_price - item.high) / price_gap) * ih) + info_height, Math.abs((Number(item.high - item.low) / price_gap) * ih));
            var rect_height = Math.abs((Number(item.close - item.open) / price_gap) * ih);
            var k_rect_up = svg.Rect(ix, Math.abs(((Number(item.close) - high_price) / price_gap) * ih) + info_height, rect_width, rect_height);
            k_line_up.attr({
                class: "k_line_up",
            });
            if (rect_height > 1) {
                k_rect_up.attr({
                    class: "k_rect_up",
                });
            }
            else {
                k_rect_up.attr({
                    class: "k_rect_up_fill",
                });
            }
            var yesterday_close = 0;
            if (index == 0) {
                // 获取前一天的收盘价
                yesterday_close = (_a = first_show_kline === null || first_show_kline === void 0 ? void 0 : first_show_kline[0]) === null || _a === void 0 ? void 0 : _a.close;
            }
            else {
                yesterday_close = data.klines[index - 1].close;
            }
            if (item.close == item.open) {
                k_line_up.attr({
                    class: yesterday_close > item.close ? "k_line_down" : yesterday_close < item.close ? "k_line_up" : "k_line_flat",
                });
                k_rect_up.attr({
                    class: yesterday_close > item.close ? "k_rect_down" : yesterday_close < item.close ? "k_rect_up_fill" : "k_rect_flat",
                });
            }
        }
        else {
            var k_line_down = svg.VLine(line_x, Math.abs(Number((high_price - Number(item.high)) / price_gap) * ih) + info_height, Math.abs(((Number(item.high) - Number(item.low)) / price_gap) * ih));
            var k_rect_down = svg.Rect(ix, Math.abs(((Number(high_price) - Number(item.open)) / price_gap) * ih) + info_height, rect_width, Math.abs((Number(item.close) - Number(item.open)) / price_gap) * ih);
            k_rect_down.attr({
                class: "k_rect_down",
            });
            k_line_down.attr({
                class: "k_line_down",
            });
        }
    });
    // MA5均线
    var average_path_d_ma5 = [];
    data.maArr.map(function (v, index) {
        if (v.ma5 != null) {
            average_path_d_ma5.push({ x: x_interval / 2 + index * x_interval, y: Math.abs((high_price - v.ma5) / price_gap) * ih });
        }
    });
    var average_path_ma5 = svg.Path(average_path_d_ma5);
    average_path_ma5.attr({
        class: "average_path_ma5",
        transform: "translate(0," + (info_height + 1) + ")",
    });
    // MA10均线
    var average_path_d_ma10 = [];
    data.maArr.map(function (v, index) {
        if (v.ma10 != null) {
            average_path_d_ma10.push({ x: x_interval / 2 + index * x_interval, y: Math.abs((high_price - v.ma10) / price_gap) * ih });
        }
    });
    var average_path_ma10 = svg.Path(average_path_d_ma10);
    average_path_ma10.attr({
        class: "average_path_ma10",
        transform: "translate(0," + (info_height + 1) + ")",
    });
    var start_date = (_b = (_a = data === null || data === void 0 ? void 0 : data.klines) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.date;
    var middle_date = ((_c = data === null || data === void 0 ? void 0 : data.klines) === null || _c === void 0 ? void 0 : _c.length) > 2 ? (_e = (_d = data === null || data === void 0 ? void 0 : data.klines) === null || _d === void 0 ? void 0 : _d[Math.floor(data.klines.length / 2)]) === null || _e === void 0 ? void 0 : _e.date : null;
    var end_date = ((_f = data === null || data === void 0 ? void 0 : data.klines) === null || _f === void 0 ? void 0 : _f.length) > 2 ? (_h = (_g = data === null || data === void 0 ? void 0 : data.klines) === null || _g === void 0 ? void 0 : _g[data.klines.length - 1]) === null || _h === void 0 ? void 0 : _h.date : null;
    var kline_nums = data.klines.length;
    //开始日期
    if (start_date) {
        var time1 = svg.Text(3, options.height - (info_height - fontsize) / 2 - 1);
        time1.ele.textContent = start_date.substring(5, 10);
    }
    // 中间日期
    if (middle_date) {
        if (kline_nums >= 20 && kline_nums <= options.count) {
            var time2 = svg.Text(((x_interval - rect_width) / 2 + x_interval * kline_nums - 32) / 2 + 3, options.height - (info_height - fontsize) / 2 - 1);
            time2.ele.textContent = middle_date.substring(5, 10);
        }
    }
    //结束日期
    if (end_date) {
        if (kline_nums >= 10 && kline_nums <= options.count) {
            var time3 = svg.Text((x_interval - rect_width) / 2 + x_interval * kline_nums + (kline_nums == options.count ? -3 : 5), options.height - (info_height - fontsize) / 2 - 1);
            time3.ele.textContent = end_date.substring(5, 10);
            time3.attr({ "text-anchor": "end" });
        }
    }
    // if(high_price != 0 && middle_price != 0 && low_price != 0){
    //价格1
    var price1 = svg.Text(2, info_height + fontsize);
    price1.attr({ class: "price1 c_up" });
    price1.ele.textContent = high_price.toFixed(decimal);
    //价格2
    var price2 = svg.Text(2, height / 2 + fontsize / 2 - 1);
    price2.attr({ class: "price1 c_draw" });
    price2.ele.textContent = middle_price.toFixed(decimal);
    //价格3
    var price3 = svg.Text(2, options.height - info_height - 2);
    price3.attr({ class: "price3 c_down" });
    price3.ele.textContent = low_price.toFixed(decimal);
    // }
    //水印
    (0, watermark_1.drawWaterMark)(svg.ele, (options.width - 120) / 2, (options.height - 26) / 2);
    //外框
    var outborder = svg.Rect(0, 0, options.width, options.height);
    outborder.attr({ class: "out_border" });
    //K线交互容器
    if (options.interaction) {
        var linerect = document.createElement("div");
        linerect.className = "time_ui_c";
        linerect.style.backgroundColor = "rgba(0,0,0,0)";
        linerect.style.width = options.width - 2 + "px";
        linerect.style.left = "1px";
        linerect.style.top = info_height + "px";
        linerect.style.height = options.height - info_height * 2 + "px";
        container.appendChild(linerect);
        var x_cross_div_1 = document.createElement("div");
        x_cross_div_1.className = "x_cross_div_k";
        x_cross_div_1.style.left = "1px";
        x_cross_div_1.style.width = (width - 2).toString() + "px";
        linerect.appendChild(x_cross_div_1);
        var y_cross_div_1 = document.createElement("div");
        y_cross_div_1.className = "y_cross_div_k";
        y_cross_div_1.style.top = "0px";
        y_cross_div_1.style.height = options.height - info_height * 2 + "px";
        linerect.appendChild(y_cross_div_1);
        var y_cross_info_1 = document.createElement("div");
        y_cross_info_1.className = "y_cross_info";
        linerect.appendChild(y_cross_info_1);
        linerect.addEventListener("mousemove", function (e) {
            // if(options.refreshTime){
            //   timer && clearInterval(timer)
            // }
            var showindex = Math.round(e.offsetX / x_interval);
            if (showindex < 0 || showindex >= data.klines.length) {
                if (x_cross_div_1.style.display == "none") {
                    return false;
                }
                x_cross_div_1.style.display = "none";
                y_cross_div_1.style.display = "none";
                y_cross_info_1.style.display = "none";
                changePriceInfoIndex(null);
                return false;
            }
            else {
                x_cross_div_1.style.display = "block";
                y_cross_div_1.style.display = "block";
                y_cross_info_1.style.display = "block";
            }
            var y_cross_div_left = e.offsetX > x_interval / 2 ? e.offsetX + x_interval / 2 : e.offsetX;
            var ix = (x_interval - rect_width) / 2 + showindex * x_interval;
            // // 设置十字坐标位置
            x_cross_div_1.style.top = e.offsetY + "px";
            y_cross_div_1.style.left = ix + rect_width / 2 - 1.5 + "px";
            if (y_cross_div_left <= options.width / 2) {
                y_cross_info_1.style.right = 0 + "px";
                y_cross_info_1.style.left = "";
            }
            else {
                y_cross_info_1.style.left = 0 + "px";
                y_cross_info_1.style.right = "";
            }
            var yesterday_close = 0;
            var market = Number(options.quotecode.split(".")[0]);
            //  期货期权
            var futuresArr = [113, 114, 115, 142, 220, 225];
            var optionsArr = [10, 12, 140, 141, 151, 163, 221, 226];
            if (showindex == 0) {
                // 获取前一天的收盘价
                var origin_kilnes_len = origin_klines.length;
                var current_klines_len = data.klines.length;
                if (current_klines_len < options.count || origin_kilnes_len == options.count) {
                    yesterday_close = data.pre_kprice;
                }
                else if (origin_kilnes_len > options.count) {
                    var lastData = origin_klines.slice(-(options.count + 1), -options.count)[0];
                    if (futuresArr.indexOf(market) > -1 || optionsArr.indexOf(market) > -1) {
                        yesterday_close = lastData.settle == 0 ? lastData.close : lastData.settle;
                    }
                    else {
                        yesterday_close = lastData.close;
                    }
                }
            }
            else {
                var lastData = data.klines[showindex - 1];
                if (futuresArr.indexOf(market) > -1 || optionsArr.indexOf(market) > -1) {
                    yesterday_close = lastData.settle == 0 ? lastData.close : lastData.settle;
                }
                else {
                    yesterday_close = lastData.close;
                }
            }
            var info_detail = "<div class=\"y_cross_info_detail\">\n        <div><span>\u65F6\u95F4\uFF1A</span><span>".concat(data.klines[showindex].date, "</span></div>\n        <div><span>\u5F00\uFF1A</span><span class=").concat((0, tools_1.formatCompareColor)(data.klines[showindex].open, yesterday_close), ">").concat((0, tools_1.formatNumToFixed)(data.klines[showindex].open, 0, decimal, "", ""), "</span></div>\n        <div><span>\u9AD8\uFF1A</span><span class=").concat((0, tools_1.formatCompareColor)(data.klines[showindex].high, yesterday_close), ">").concat((0, tools_1.formatNumToFixed)(data.klines[showindex].high, 0, decimal, "", ""), "</span></div>\n        <div><span>\u4F4E\uFF1A</span><span class=").concat((0, tools_1.formatCompareColor)(data.klines[showindex].low, yesterday_close), ">").concat((0, tools_1.formatNumToFixed)(data.klines[showindex].low, 0, decimal, "", ""), "</span></div>\n        <div><span>\u6536\uFF1A</span><span class=").concat((0, tools_1.formatCompareColor)(data.klines[showindex].close, yesterday_close), ">").concat((0, tools_1.formatNumToFixed)(data.klines[showindex].close, 0, decimal, "", ""), "</span></div>\n        <div><span>\u6DA8\u8DCC\u989D\uFF1A</span><span class=").concat((0, tools_1.formatSingleColor)(data.klines[showindex].zde), ">").concat(data.klines[showindex].zde.toFixed(decimal), "</span></div>\n        <div><span>\u6DA8\u8DCC\u5E45\uFF1A</span><span class=").concat((0, tools_1.formatSingleColor)(data.klines[showindex].zde), ">").concat(data.klines[showindex].zdf, "</span></div>\n        ").concat(Number(data.klines[showindex].huanshou) == 0 ? "" : "<div><span>\u6362\u624B\u7387\uFF1A</span><span>".concat((0, tools_1.formatNumToFixed)(data.klines[showindex].huanshou, 0, 2, "", "%"), "</span></div>"), "\n        \n      </div>");
            y_cross_info_1.innerHTML = info_detail;
            changePriceInfoIndex(showindex);
        });
        linerect.addEventListener("mouseleave", function () {
            if (x_cross_div_1.style.display == "none")
                return false;
            x_cross_div_1.style.display = "none";
            y_cross_div_1.style.display = "none";
            y_cross_info_1.style.display = "none";
            changePriceInfoIndex(null);
        });
        x_cross_div_1.addEventListener("mousemove", function (e) {
            e.stopPropagation();
        });
        y_cross_div_1.addEventListener("mousemove", function (e) {
            e.stopPropagation();
        });
        y_cross_info_1.addEventListener("mousemove", function (e) {
            e.stopPropagation();
        });
    }
}
exports.drawKLine = drawKLine;
/** 绘制右上角价格信息 */
function ChangePriceInfoIndex(info_price, data, options, stockname, ma_width) {
    return function changeIndex(index) {
        var thisdata = data.klines[data.klines.length - 1];
        if (index != null && data.klines[index]) {
            thisdata = data.klines[index];
        }
        if (thisdata) {
            // if (thisdata && options.show_price_info) {
            if (thisdata.zde > 0) {
                info_price.textContent = "".concat(thisdata.close.toFixed(data.decimal), " \u25B2").concat(thisdata.zde.toFixed(data.decimal), " \u25B2").concat(thisdata.zdf);
            }
            else if (thisdata.zde < 0) {
                info_price.textContent = "".concat(thisdata.close.toFixed(data.decimal), " \u25BC").concat(thisdata.zde.toFixed(data.decimal), " \u25BC").concat(thisdata.zdf);
            }
            else {
                info_price.textContent = "".concat(thisdata.close.toFixed(data.decimal), "\u3000").concat(thisdata.zde.toFixed(data.decimal), "\u3000").concat(thisdata.zdf);
            }
            if (thisdata.zde > 0) {
                info_price.setAttribute("class", "info_price c_up");
            }
            else if (thisdata.zde < 0) {
                info_price.setAttribute("class", "info_price c_down");
            }
            else {
                info_price.setAttribute("class", "info_price c_draw");
            }
            //判断空间是否够显示两个文本信息
            var w1 = stockname.getComputedTextLength();
            var w2 = info_price.getComputedTextLength();
            if (w1 + w2 + ma_width + 20 > options.width) {
                info_price.textContent = "";
            }
            else if (w1 + w2 + ma_width + 30 > options.width) {
                //显示不下
                var wc = (w1 + w2 + ma_width + 30 - options.width) / 2; //宽度差值/2
                stockname.setAttribute("textLength", w1 - wc + "px");
                info_price.setAttribute("textLength", w2 - wc - 5 + "px");
            }
        }
        else {
            info_price.textContent = "";
        }
    };
}
