"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawWaterMark = void 0;
var logoimg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAnBAMAAABUEX/sAAAAKlBMVEUAAACurbuurbuurbuurbuurbuurbuurbuurbuurbuurbuurbuurbuurbvoDS5PAAAADnRSTlMATQk4SCswGydDEz4NIjv513wAAATlSURBVEjHtZbL70tBFMdHUVrt4qtUqyRXveKx6HXFMxIXIWKjoSREUvFYSCRq05BIFIsmIlFigUha8QgiQVjws6h4JKSLlgSR9H9xzrkz017qZ8M3v187M/fczzlz5sxM1ZDijvpPmhovn1b/R0sSs2PNQTfqeWtUWJFSqaRGaIrnlU17ouet+M1gZSI1ddOgWwPm/IoGYNoHscYHZqzj9mRgoxmfIK+FFU3FsTxnuwnC7BwXfbNePw+0/46OuJkp6Da6pj8NyKrx0EJ7ip0W/b1+SS2uVoBMtVr9dlJZXcCcySi7kpH4w4cPi2TyUKs5El2QrBUs+ilyizDQazP93UAhhie1DPfeIKzLo9H0V8OGer1+kcI4x+i1P/KBFvtmzncboKy1sLGXFUQ+3wJyeSNHlUR7yCpodS16JQLNIfSMsrKeJYilX5+CNFO52FiBnrwLzB5K8pYtW8hkxhajgkaHE5JNdLQcRiefI1AhDkLrqkjSQFmFFB4Zic7FYGCMbulOzqHxJz0yZS2gEJ1kvd4NVcfOv6EjNoUuoelf9IzTUy6Sjc7HLBWjN0Los6PQU48evWvQA2sfaeVDlGLXOOFiuuxcnlMYnfgTehIQoO9XS9UTi6uiMydp7Zkrb9Xo0/GDrRTjdhgdp6HuKDRtv/dsKAFloJVtK42+oaIcelzXRYUL5he0+JdKDKPnAQstOnU7zyrKugg5oxJcgLMmazR1NoXRkqN+QwI6FkK3MGOyRtvjY55UE1unHUkHnsQ02qdHI9AvD/T73115NkBXkP0Dmiy3OuoUWM2KPJSoU0ePHianR0lHpNJN8RFn7jC6h9QAnXKkPmpkyyYbHPVWV4mLoELeXmc1aPL8fVPuIC4gXSq5YXQR6cmjl/HLZ7L2pbspjuEUhBMymWelC3zGMNpFRqMT9fq5x3VRv2kuHYhOTON8j0ZLBbV1qWSH0U8xS9CjNUHIadUbbOaFdLoxulQ6YN2PRvuYHaCnVKvtT1Wj9jD6ScQ3+VTs5Syj6aQcoB0plXCuV9DyMFoou2CVc8xlRaE48iUjUiNtRveMs5i5BpIW7dLVgXVkZNG3Op0PwOtO5wFQ0NakK6oIecuWAaPnGZtJvHq6VFIBuoE5Naym6Vp0WZa7IMTp9lTLOuJgrq2HjKAnGhvykTWPBM3xpmtYQyiLPmvQU/RrbIcrfCPagUnUEjTXqoy0mMiKBo0vHie205lPKz8eusVZdwEeFlXsoWruzx7FaNYzpedBVA6ia9EvSqX9wLVSaZdFxzhLwKBAGkAzQBf1mGuSNc34KPIu49k4Fn3V89YCmz1vvUUn5wYJN5U2RRIr6Df6t4Jv7uFJgtb2GeWSx+g4CYk05WWdV4HO1eiJ3GRbciGF+UZbEa+CGUmkmmraOGiRyxO0PyVfOQShN+J8AYsbdFedpLJs6PBbcuJ9zsd/5CvkVtA7LTr8y68ly2L28vLtni8nSs985tQy7BhrgDmyqWZHJJaDIJUFvcmgo+GooxIf6x02d9UdQGaY2O+n5WxcwRS71knySP00v0dqC9ofG3sO8j/mi4OBGmbDyP0XcSl23VcfsWMfb9LDjNkm5cnHflTcPAqGpo2N9Q95RsfVsBbITK0iX1d1lRo4Ez3es2qvNPo/eGhpv8ufp9X4olmfUP9L9/4p7SdZWHPdrnvhIAAAAABJRU5ErkJggg=='; //w180 h39
/**
 * 水印图片
 */
function drawWaterMark(svg, width, height) {
    var img = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    img.setAttribute('href', logoimg);
    img.setAttribute('x', width.toString());
    img.setAttribute('y', height.toString());
    img.setAttribute('width', '120');
    img.setAttribute('height', '26');
    svg.appendChild(img);
}
exports.drawWaterMark = drawWaterMark;
