"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countCommonKData = void 0;
function countCommonKData(options, data) {
    var _a, _b, _c;
    var first_data = (_a = data.klines.slice(-options.count)) === null || _a === void 0 ? void 0 : _a[0];
    var max_price = (_b = first_data === null || first_data === void 0 ? void 0 : first_data.high) !== null && _b !== void 0 ? _b : 0;
    var min_price = (_c = first_data === null || first_data === void 0 ? void 0 : first_data.low) !== null && _c !== void 0 ? _c : 0;
    data.klines.slice(-options.count).forEach(function (v) {
        if (v.high > max_price)
            max_price = v.high;
        if (v.low < min_price)
            min_price = v.low;
    });
    var middle_price = (max_price + min_price) / 2;
    var price_span = Math.max(Math.abs(max_price - middle_price), Math.abs(min_price - middle_price));
    // 平线 上下限浮动5%
    if (price_span == 0) {
        price_span = Math.abs(middle_price * 0.05);
    }
    var high_price = Math.round((middle_price + price_span + price_span * options.height_float_ratio) * Math.pow(10, data.decimal)) / Math.pow(10, data.decimal);
    var low_price = Math.round((middle_price - price_span - price_span * options.height_float_ratio) * Math.pow(10, data.decimal)) / Math.pow(10, data.decimal);
    var info_height = options.fontsize * 1.8;
    var x_interval = options.width / options.count;
    var y_interval = (options.height - info_height * 2 - 2) / Math.abs(high_price - low_price);
    return {
        max_price: max_price,
        min_price: min_price,
        high_price: high_price,
        low_price: low_price,
        middle_price: middle_price,
        y_interval: y_interval,
        x_interval: x_interval,
        info_height: info_height,
    };
}
exports.countCommonKData = countCommonKData;
