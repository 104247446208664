"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumToFixed = exports.formatSingleColor = exports.formatCompareColor = exports.getArrayMinNumber = exports.getArrayMaxNumber = exports.getTrendMaxOrMin = exports.getArrayItemSum = exports.axisIntAdd = exports.getTimeString = void 0;
/**
 * 提取时间字符串 09:30
 * @param input 例如 202310240930
 */
function getTimeString(input) {
    if (input) {
        var inputstr = input.toString();
        if ((inputstr === null || inputstr === void 0 ? void 0 : inputstr.length) > 3) {
            return inputstr.substring(inputstr.length - 4, inputstr.length - 2) + ":" + inputstr.substring(inputstr.length - 2, inputstr.length);
        }
    }
    return null;
}
exports.getTimeString = getTimeString;
/**
 * 修整坐标数字+0.5
 */
function axisIntAdd(input) {
    return Math.trunc(input) + 0.5;
}
exports.axisIntAdd = axisIntAdd;
/**
 * 传入一个数组，并且计算从数组 begin 到 end 之间所有的元素的和，并将其返回
 * @param arr
 * @param begin
 * @param end
 */
function getArrayItemSum(arr, begin, end) {
    var res = 0;
    arr.forEach(function (item, index) {
        if (index >= begin && index <= end) {
            res += item;
        }
    });
    return res;
}
exports.getArrayItemSum = getArrayItemSum;
/**
 * 获取分时行情trend数组里的特定的值的最大值,并且 0 不计入
 * @param arr
 * @param prePrice
 * @param key
 * @param type
 */
function getTrendMaxOrMin(arr, key, type) {
    if (arr[0] == undefined)
        return 0;
    var res = arr[0][key];
    arr.forEach(function (item) {
        if (item[key] == null) {
        }
        else if (item[key] != null && res == null) {
            res = item[key];
        }
        else {
            if (type == "max") {
                res = Number(item[key]) > res ? Number(item[key]) : res;
            }
            else if (type == "min") {
                if (res == 0) {
                    res = Number(item[key]);
                }
                else {
                    res = Number(item[key]) < res ? Number(item[key]) : res;
                }
            }
        }
    });
    if (res == null)
        return null;
    return Number(res);
}
exports.getTrendMaxOrMin = getTrendMaxOrMin;
/**
 * 获取数组中的最大值
 * @param arr
 */
function getArrayMaxNumber(arr) {
    var array = [];
    arr.forEach(function (item) {
        if (item != null) {
            array.push(item);
        }
    });
    if (array.length == 0)
        return 0;
    return Math.max.apply(Math, array);
}
exports.getArrayMaxNumber = getArrayMaxNumber;
/**
 *
 * @param arr 获取数组中的最小值 但不包含0
 */
function getArrayMinNumber(arr) {
    var array = [];
    arr.forEach(function (item) {
        if (item != null) {
            array.push(item);
        }
    });
    if (array.length == 0)
        return 0;
    return Math.min.apply(Math, array);
}
exports.getArrayMinNumber = getArrayMinNumber;
/**
 * 双字段比色
 * @param value
 * @param value1
 * @returns
 */
function formatCompareColor(value, value1) {
    if (value == null || value == "-" || value1 == null || value1 == "-") {
        return "";
    }
    if (value.toString() == "0" || value.toString() == "0.0" || value.toString() == "0.00" || value.toString() == "0.000" || value.toString() == "0.0000") {
        return "gray";
    }
    if (parseFloat(value) > parseFloat(value1)) {
        return "rise";
    }
    else if (parseFloat(value) < parseFloat(value1)) {
        return "fall";
    }
    else {
        return "";
    }
}
exports.formatCompareColor = formatCompareColor;
/**
 * 单字段比色
 * @param value
 * @returns
 */
function formatSingleColor(value) {
    if (value == null || value == "-" || value == undefined) {
        return "";
    }
    return value > 0 ? "rise" : value < 0 ? "fall" : "";
}
exports.formatSingleColor = formatSingleColor;
/**
 * 格式化字段
 * @param value 值
 * @param rate 缩放
 * @param tofixed 保留小数
 * @param prefix 前缀
 * @param suffix 后缀
 * @returns
 */
function formatNumToFixed(value, rate, tofixed, prefix, suffix) {
    if (prefix === void 0) { prefix = ""; }
    if (suffix === void 0) { suffix = ""; }
    if (value == "-" || value == undefined || isNaN(value))
        return "-";
    var num = Number(value);
    var r = Number(rate);
    var temp = Math.pow(10, r);
    if (num) {
        return prefix + (num / temp).toFixed(tofixed) + suffix;
    }
    else {
        return prefix + num.toFixed(tofixed) + suffix;
    }
}
exports.formatNumToFixed = formatNumToFixed;
