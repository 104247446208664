"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawTime = void 0;
var watermark_1 = require("../watermark");
/**
 * 绘图
 */
function drawTime(svg, data, commondata, options, container) {
    var high_price = commondata.high_price, low_price = commondata.low_price, y_scale = commondata.y_scale, x_scale = commondata.x_scale, info_height = commondata.info_height;
    var pre_price = data.pre_price, decimal = data.decimal;
    var width = options.width, height = options.height, fontsize = options.fontsize;
    //股票名称
    var stockname = svg.Text(5, info_height - fontsize / 2);
    stockname.ele.textContent = data.name;
    stockname.attr({ 'class': 'topinfo' });
    //右上角价格信息
    var info_price = svg.Text(options.width - 2, info_height - options.fontsize / 2);
    info_price.attr({ class: 'info_price', 'text-anchor': 'end' });
    var changePriceInfoIndex = ChangePriceInfoIndex(info_price.ele, data, options, stockname.ele);
    changePriceInfoIndex(null);
    //分时线填充色
    var time_path_shadow_d = [{ x: 1, y: height - info_height * 2 - 2 }];
    data.trends.forEach(function (v, index) {
        time_path_shadow_d.push({ x: index * x_scale, y: (high_price - v.close) * y_scale });
    });
    time_path_shadow_d.push({ x: (data.trends.length - 1) * x_scale, y: height - info_height * 2 - 2 });
    var time_path_shadow = svg.Path(time_path_shadow_d);
    time_path_shadow.attr({
        'class': 'timeshadow',
        'transform': 'translate(0,' + (info_height + 1) + ')'
    });
    //上线
    var line1 = svg.HLine(0, info_height, options.width);
    line1.attr({ class: 'infobox_border' });
    //中线
    var line2 = svg.HLine(0, options.height / 2, options.width);
    line2.attr({ class: 'average_border' });
    //下线
    var line3 = svg.HLine(0, options.height - info_height, options.width);
    line3.attr({ class: 'infobox_border' });
    //均线
    var average_path_d = [];
    data.trends.filter(function (v) { return v.average != 0; }).forEach(function (v) {
        average_path_d.push({ x: v.index * x_scale, y: (high_price - v.average) * y_scale });
    });
    var average_path = svg.Path(average_path_d);
    average_path.attr({
        class: 'average_path',
        transform: 'translate(0,' + (info_height + 1) + ')'
    });
    //分时线
    var time_path = svg.Path(data.trends.map(function (v, index) {
        return {
            x: index * x_scale,
            y: (high_price - v.close) * y_scale
        };
    }));
    time_path.attr({
        class: 'time_path',
        'transform': 'translate(0,' + (info_height + 1) + ')'
    });
    //开始时间
    if (commondata.start_time) {
        var time1 = svg.Text(3, options.height - (info_height - fontsize) / 2 - 1);
        time1.ele.textContent = commondata.start_time;
    }
    //结束时间
    if (commondata.end_time) {
        var time2 = svg.Text(options.width - 3, options.height - (info_height - fontsize) / 2 - 1);
        time2.ele.textContent = commondata.end_time;
        time2.attr({ 'text-anchor': 'end' });
    }
    //价格1
    var price1 = svg.Text(2, info_height + fontsize);
    price1.attr({ class: 'price1 c_up' });
    price1.ele.textContent = high_price.toFixed(decimal);
    //价格2
    var price2 = svg.Text(2, height / 2 + fontsize / 2 - 1);
    price2.attr({ class: 'price1 c_draw' });
    price2.ele.textContent = pre_price.toFixed(decimal);
    //价格3
    var price3 = svg.Text(2, options.height - info_height - 2);
    price3.attr({ class: 'price3 c_down' });
    price3.ele.textContent = low_price.toFixed(decimal);
    //水印
    (0, watermark_1.drawWaterMark)(svg.ele, (options.width - 120) / 2, (options.height - 26) / 2);
    //外框
    var outborder = svg.Rect(0, 0, options.width, options.height);
    outborder.attr({ class: 'out_border' });
    //分时交互
    //分时交互容器
    if (options.interaction) {
        var linerect = document.createElement('div');
        linerect.className = 'time_ui_c';
        linerect.style.backgroundColor = 'rgba(0,0,0,0)';
        linerect.style.width = (options.width - 2) + 'px';
        linerect.style.left = '1px';
        linerect.style.top = (info_height) + 'px';
        linerect.style.height = (options.height - info_height * 2) + 'px';
        container.appendChild(linerect);
        var x_cross_div_1 = document.createElement('div');
        x_cross_div_1.className = 'x_cross_div';
        x_cross_div_1.style.left = '1px';
        x_cross_div_1.style.width = (width - 2).toString() + 'px';
        linerect.appendChild(x_cross_div_1);
        var y_cross_div_1 = document.createElement('div');
        y_cross_div_1.className = 'y_cross_div';
        y_cross_div_1.style.top = '0px';
        y_cross_div_1.style.height = (options.height - info_height * 2) + 'px';
        linerect.appendChild(y_cross_div_1);
        var y_cross_time_1 = document.createElement('div');
        y_cross_time_1.className = 'y_cross_time';
        y_cross_time_1.style.height = (info_height - 3) + 'px';
        y_cross_time_1.style.lineHeight = (info_height - 3) + 'px';
        y_cross_div_1.appendChild(y_cross_time_1);
        linerect.addEventListener('mousemove', function (e) {
            var showindex = Math.round(e.offsetX / x_scale);
            if (showindex < 0 || showindex >= data.trends.length) {
                if (x_cross_div_1.style.display == 'none') {
                    return false;
                }
                x_cross_div_1.style.display = 'none';
                y_cross_div_1.style.display = 'none';
                changePriceInfoIndex(null);
                return false;
            }
            else {
                x_cross_div_1.style.display = 'block';
                y_cross_div_1.style.display = 'block';
            }
            y_cross_div_1.style.left = e.offsetX + 'px';
            var y = (high_price - data.trends[showindex].close) * y_scale;
            x_cross_div_1.style.top = y + 'px';
            y_cross_time_1.innerText = data.trends[showindex].time;
            var y_cross_time_left = y_cross_time_1.clientWidth / 2;
            y_cross_time_1.style.left = -y_cross_time_left + 'px';
            if (e.offsetX < y_cross_time_left)
                y_cross_time_1.style.left = -e.offsetX + 'px'; //不要移出去了
            if (e.offsetX > (width - y_cross_time_left - 2))
                y_cross_time_1.style.left = -e.offsetX + (width - 2 - y_cross_time_left * 2) + 'px'; //不要移出去了
            y_cross_time_1.style.bottom = '-' + (y_cross_time_1.clientHeight + 1) + 'px';
            changePriceInfoIndex(showindex);
        });
        linerect.addEventListener('mouseleave', function () {
            if (x_cross_div_1.style.display == 'none')
                return false;
            x_cross_div_1.style.display = 'none';
            y_cross_div_1.style.display = 'none';
            changePriceInfoIndex(null);
        });
        x_cross_div_1.addEventListener('mousemove', function (e) {
            e.stopPropagation();
        });
        y_cross_div_1.addEventListener('mousemove', function (e) {
            e.stopPropagation();
        });
    }
}
exports.drawTime = drawTime;
/** 绘制右上角价格信息 */
function ChangePriceInfoIndex(info_price, data, options, stockname) {
    return function changeIndex(index) {
        var thisdata = data.trends[data.trends.length - 1];
        if (index != null && data.trends[index]) {
            thisdata = data.trends[index];
        }
        if (thisdata) {
            if (thisdata.zde > 0) {
                info_price.textContent = "".concat(thisdata.close.toFixed(data.decimal), " \u25B2").concat(thisdata.zde.toFixed(data.decimal), " \u25B2").concat(thisdata.zdf);
            }
            else if (thisdata.zde < 0) {
                info_price.textContent = "".concat(thisdata.close.toFixed(data.decimal), " \u25BC").concat(thisdata.zde.toFixed(data.decimal), " \u25BC").concat(thisdata.zdf);
            }
            else {
                info_price.textContent = "".concat(thisdata.close.toFixed(data.decimal), "\u3000").concat(thisdata.zde.toFixed(data.decimal), "\u3000").concat(thisdata.zdf);
            }
            if (thisdata.zde > 0) {
                info_price.setAttribute('class', 'info_price c_up');
            }
            else if (thisdata.zde < 0) {
                info_price.setAttribute('class', 'info_price c_down');
            }
            else {
                info_price.setAttribute('class', 'info_price c_draw');
            }
            //判断空间是否够显示两个文本信息
            var w1 = stockname.getComputedTextLength();
            var w2 = info_price.getComputedTextLength();
            if (w1 + w2 + 10 > (options.width - 2)) { //显示不下
                var wc = (w1 + w2 + 10 - (options.width - 2)) / 2; //宽度差值/2
                stockname.setAttribute('textLength', (w1 - wc) + 'px');
                info_price.setAttribute('textLength', (w2 - wc) + 'px');
            }
        }
        else {
            info_price.textContent = '';
        }
    };
}
