"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countCommonData = void 0;
var tools_1 = require("../tools");
/**
 * 准备数据
 */
function countCommonData(options, data) {
    var _a, _b, _c, _d, _e, _f;
    var pre_price = data.pre_price;
    var max_price = pre_price;
    var min_price = pre_price;
    data.trends.forEach(function (v) {
        if (v.high > max_price)
            max_price = v.high;
        if (v.low < min_price)
            min_price = v.low;
        if (v.average != 0) { //均价为0则是无效数据
            if (v.average > max_price)
                max_price = v.average;
            if (v.average < min_price)
                min_price = v.average;
        }
    });
    var price_span = Math.max(Math.abs(max_price - pre_price), Math.abs(min_price - pre_price));
    //平线 浮动5%
    if (price_span == 0) {
        price_span = Math.abs(pre_price * 0.05);
    }
    var high_price = Math.round((pre_price + price_span) * Math.pow(10, data.decimal)) / Math.pow(10, data.decimal);
    var low_price = Math.round((pre_price - price_span) * Math.pow(10, data.decimal)) / Math.pow(10, data.decimal);
    var info_height = options.fontsize * 1.8;
    var y_scale = (options.height - info_height * 2) / Math.abs(high_price - low_price);
    var x_scale = (options.width / data.trendstotal);
    var start_time = (0, tools_1.getTimeString)((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.tradePeriods) === null || _a === void 0 ? void 0 : _a.periods) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.b);
    var end_time = (0, tools_1.getTimeString)((_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.tradePeriods) === null || _d === void 0 ? void 0 : _d.periods) === null || _e === void 0 ? void 0 : _e[data.tradePeriods.periods.length - 1]) === null || _f === void 0 ? void 0 : _f.e);
    return {
        max_price: max_price,
        min_price: min_price,
        high_price: high_price,
        low_price: low_price,
        y_scale: y_scale,
        x_scale: x_scale,
        start_time: start_time,
        end_time: end_time,
        info_height: info_height
    };
}
exports.countCommonData = countCommonData;
