"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addStyle = void 0;
/**
 * add style
 */
function addStyle(css) {
    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    // style.type = 'text/css';
    // if (style.styleSheet) {
    //   style.styleSheet.cssText = css;
    // } else {
    style.appendChild(document.createTextNode(css));
    // }
    head.appendChild(style);
}
exports.addStyle = addStyle;
