"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DSVG = void 0;
var tools_1 = require("../tools");
/**
 * svg绘图封装
 */
var DSVG = /** @class */ (function () {
    function DSVG(container, width, height) {
        this.ele = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        this.ele.setAttribute('width', width.toString());
        this.ele.setAttribute('height', height.toString());
        container.appendChild(this.ele);
    }
    DSVG.prototype.attr = function (options) {
        setAttr(this.ele, options);
    };
    /** 线段 */
    DSVG.prototype.Line = function (x1, y1, x2, y2) {
        var newline = new Line(x1, y1, x2, y2);
        this.ele.appendChild(newline.ele);
        return newline;
    };
    /** 横向线段，带像素修正 */
    DSVG.prototype.HLine = function (x, y, width) {
        var newline = new HLine(x, y, width);
        this.ele.appendChild(newline.ele);
        return newline;
    };
    /** 竖向线段，带像素修正 */
    DSVG.prototype.VLine = function (x, y, height) {
        var newline = new VLine(x, y, height);
        this.ele.appendChild(newline.ele);
        return newline;
    };
    /** 文字 */
    DSVG.prototype.Text = function (x, y) {
        var newtext = new Text(x, y);
        this.ele.appendChild(newtext.ele);
        return newtext;
    };
    /** 矩形，带像素修正 */
    DSVG.prototype.Rect = function (x, y, width, height) {
        var newrect = new Rect(x, y, width, height);
        this.ele.appendChild(newrect.ele);
        return newrect;
    };
    /** 折线，带精度修正 */
    DSVG.prototype.Path = function (xylist) {
        var newpath = new Path(xylist);
        this.ele.appendChild(newpath.ele);
        return newpath;
    };
    return DSVG;
}());
exports.DSVG = DSVG;
/**
 * 矩形，带像素修正
 */
var Rect = /** @class */ (function () {
    function Rect(x, y, width, height) {
        this.ele = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        setAttr(this.ele, {
            x: (0, tools_1.axisIntAdd)(x),
            y: (0, tools_1.axisIntAdd)(y),
            width: (0, tools_1.axisIntAdd)(width - 1),
            height: height >= 2 ? Math.trunc(height) - 1 : 1
        });
    }
    Rect.prototype.attr = function (options) {
        setAttr(this.ele, options);
    };
    return Rect;
}());
/**
 * 线段
 */
var Line = /** @class */ (function () {
    function Line(x1, y1, x2, y2) {
        this.ele = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        setAttr(this.ele, { x1: x1, y1: y1, x2: x2, y2: y2 });
    }
    Line.prototype.attr = function (options) {
        setAttr(this.ele, options);
    };
    return Line;
}());
/**
 * 横向线段，带像素修正
 */
var HLine = /** @class */ (function () {
    function HLine(x, y, width) {
        this.ele = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        setAttr(this.ele, {
            x1: x,
            y1: (0, tools_1.axisIntAdd)(y),
            x2: x + width,
            y2: (0, tools_1.axisIntAdd)(y)
        });
    }
    HLine.prototype.attr = function (options) {
        setAttr(this.ele, options);
    };
    return HLine;
}());
/**
 * 竖向线段，带像素修正
 */
var VLine = /** @class */ (function () {
    function VLine(x, y, height) {
        this.ele = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        setAttr(this.ele, {
            x1: (0, tools_1.axisIntAdd)(x),
            y1: y,
            x2: (0, tools_1.axisIntAdd)(x),
            y2: y + height
        });
    }
    VLine.prototype.attr = function (options) {
        setAttr(this.ele, options);
    };
    return VLine;
}());
/** 文本 */
var Text = /** @class */ (function () {
    function Text(x, y) {
        this.ele = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        setAttr(this.ele, { x: x, y: y });
    }
    Text.prototype.attr = function (options) {
        setAttr(this.ele, options);
    };
    return Text;
}());
/** 折线，带精度修正 */
var Path = /** @class */ (function () {
    function Path(xylist) {
        this.ele = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        var d = [];
        xylist.forEach(function (v, index) {
            if (index == 0) {
                d.push("M ".concat(parseFloat((v.x).toFixed(1)), " ").concat(parseFloat((v.y).toFixed(1))));
            }
            else {
                d.push("L ".concat(parseFloat((v.x).toFixed(1)), " ").concat(parseFloat((v.y).toFixed(1))));
            }
        });
        setAttr(this.ele, {
            d: d.join(' ')
        });
    }
    Path.prototype.attr = function (options) {
        setAttr(this.ele, options);
    };
    return Path;
}());
function setAttr(thisele, options) {
    Object.keys(options).forEach(function (v) {
        thisele.setAttribute(v, options[v].toString());
    });
}
