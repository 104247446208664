"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * 主入口
 */
require("core-js/features/object/assign");
require("core-js/features/promise");
require("core-js/features/math/trunc");
var addstyle_1 = require("./modules/addstyle");
var time_1 = require("./modules/time");
var kline_1 = require("./modules/kline");
//导入css
var csstxt = require("./css/index.css");
(0, addstyle_1.addStyle)(csstxt);
module.exports = {
    Time: time_1.Time,
    KLine: kline_1.KLine
};
